<template>
  <a-drawer width='35%' :label-col='4' :wrapper-col='14' :visible='open' @close='cancel'>
    <a-divider orientation='left'>
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref='form' :model='form' :rules='rules'>
      <a-form-model-item label='APP类型:' prop='appType' >
        <a-select placeholder="请选择" v-model="form.appType" >
          <a-select-option value="0">安卓</a-select-option>
          <a-select-option value="1">苹果</a-select-option>
          <a-select-option value="2">通用</a-select-option>
        </a-select>
<!--        <a-input-number v-model='form.appType' placeholder=' 0安卓 1苹果 2通用' :min='0' :max='2' />-->
      </a-form-model-item>
      <a-form-model-item label='版本号名称' prop='versionName'
      >
        <a-input v-model='form.versionName' placeholder='请输入版本号名称(15字)' :maxLength='15'/>
      </a-form-model-item>
      <a-form-model-item label='版本号' prop='versionCode'
      >
        <a-input v-model='form.versionCode' placeholder='请输入版本号' :maxLength='15'/>
      </a-form-model-item>
      <a-form-model-item label='更新包大小' prop='upHeSize' >
        <a-input-number v-model='form.upHeSize' placeholder='请输入更新包大小' :min='0.01' :maxLength='10' :max='9999'/>
      </a-form-model-item>
      <a-form-model-item label='更新地址类型:' prop='upUrlType' >
        <a-select placeholder="请选择" v-model="form.upUrlType" >
          <a-select-option value="0">内部</a-select-option>
          <a-select-option value="1">其它(应用市场)</a-select-option>
        </a-select>
<!--        <a-input-number v-model='form.upUrlType' placeholder=' 更新地址类型: 0内部 1其它(应用市场)' :min='0' :max='1' />-->
      </a-form-model-item>
      <a-form-model-item label='其它(应用市场)应用 【内部使用文件库】' prop='upUrl'
                         v-if="form.upUrlType == 1"
      >
        <a-input v-model='form.upUrl' placeholder='请输入其它(应用市场)应用 【内部使用文件库】' />
      </a-form-model-item>

      <a-form-model-item label='更新类型:' prop='upType'
      >
        <a-select placeholder="请选择" v-model="form.upType" >
          <a-select-option value="0">热更新</a-select-option>
          <a-select-option value="1">整包更新</a-select-option>
        </a-select>
<!--        <a-input-number v-model='form.upType' placeholder='0热更新 1整包更新' :min='0' :max='1' />-->

      </a-form-model-item>
      <a-form-model-item label='强制升级否' prop='compulsoryUp'
      >
        <a-select placeholder="请选择" v-model="form.compulsoryUp" >
          <a-select-option value="0">强制</a-select-option>
          <a-select-option value="1">可跳过</a-select-option>
        </a-select>
<!--        <a-input-number v-model='form.compulsoryUp' placeholder='请输入强制升级否 0强制 1可跳过' :min='0' :max='1' />-->

      </a-form-model-item>
<!--      <a-form-model-item label='计划更新时间: 0及时更新 1计划更新' prop='planUpType'-->
<!--      >-->
<!--        <a-input-number v-model='form.planUpType' placeholder='0及时更新 1计划更新' :min='0' :max='1' />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label='计划更新时间' prop='planUpTime'-->
<!--      >-->
<!--        <a-input v-model='form.planUpTime' placeholder='请输入计划更新时间' />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label='下载地址' prop='downloadUrl'
      >
        <a-input v-model='form.downloadUrl' placeholder='请输入下载地址' :maxLength='500'/>
<!--        <a-upload   listType='picture-card'-->
<!--                    class='avatar-uploader' :show-upload-list='false' :beforeUpload='(info) => fileBeforeUpload(info)'-->
<!--                    :customRequest='(info) => fileUploadRequest(info)' v-model="form.downloadUrl">-->
<!--          <a-input v-model='form.downloadUrl' :disabled='true' placeholder='请输入下载地址' :maxLength='255'/>-->
<!--          <div v-else>-->
<!--            <a-icon :type="loading ? 'loading' : 'plus'" />-->
<!--            <div class='ant-upload-text'>{{ loading ? '上传中' : '上传' }}</div>-->
<!--          </div>-->
<!--        </a-upload>-->
      </a-form-model-item>
      <a-form-model-item label='更新说明' prop='upNotes' >
        <a-input v-model='form.upNotes' placeholder='请输入更新说明' :maxLength='30'/>
      </a-form-model-item>
      <div class='bottom-control'>
        <a-space>
          <a-button type='primary' :loading='submitLoading' @click='submitForm'>
            保存
          </a-button>
          <a-button type='dashed' @click='cancel'>
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getVersionInfo, addVersionInfo, updateVersionInfo } from '@/api/app/versionInfo'
import UploadFileToOSS from '@/utils/upload-file-to-oss'
// import {addVersionInfo} from "@/api/app/versionInfo";
// import { Log } from '@/components/crud/Log'
export default {
  name: 'CreateForm',
  props: {} ,
  components: {}
  ,
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        appType: "0",

        upNotes: null,

        upHeSize: null,

        upUrlType: null,

        upUrl: null,

        versionName: null,

        versionCode: null,

        upType: null,

        compulsoryUp: null,

        downloadUrl: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      loading: false,
      apkFileName: null,
      rules: {
        appType: [
          {
            required: true, message: 'APP类型 : 0安卓 1苹果 2通用不能为空', trigger: 'change'
          }
        ],
        upUrlType: [
          {
            required: true, message: '更新地址类型: 0内部 1其它(应用市场)不能为空', trigger: 'change'
          }
        ],
        versionCode: [
          {
            required: true, message: '版本号不能为空', trigger: 'blur'
          }
        ],
        upType: [
          {
            required: true, message: '更新类型: 0热更新 1整包更新不能为空', trigger: 'change'
          }
        ],
        compulsoryUp: [
          {
            required: true, message: '强制升级否 0强制 1可跳过不能为空', trigger: 'blur'
          }
        ],

        downloadUrl: [
          {
            required: true, message: '下载地址不能为空', trigger: 'blur'
          }
        ]
      }
    }
  }
  ,
  filters: {}
  ,
  created() {
  }
  ,
  computed: {}
  ,
  watch: {}
  ,
  mounted() {
  }
  ,
  methods: {
    onClose() {
      this.open = false
    }
    ,
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    }
    ,
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        appType:"0",
        upNotes: null,
        upHeSize: null,
        upUrlType: null,
        upUrl: null,
        versionName: null,
        versionCode: null,
        upType: null,
        compulsoryUp: null,
        downloadUrl: null,
        createTime: null,
        remark: null
      }
      this.apkFileName = null;
      // this.$nextTick(() => {
      //   if (this.$refs.form) {
      //     this.$refs.form.resetFields()
      //   }
      // })
    }
    ,
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    }
    ,
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getVersionInfo({ 'id': id }).then(response => {
        this.form = response.data
        // this.form.appType = Number(response.data.appType);
        this.open = true
        this.formTitle = '修改'
      })
    }
    ,
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateVersionInfo(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addVersionInfo(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    /**
     * 文件上传前校验
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileBeforeUpload(fileInfo) {
      // 图片类型字段, 进行文件类型判断
      // Log.info('fileInfo', fileInfo);
      // if (!fileInfo.type.includes('apk') || !fileInfo.type.includes('wgt')) {
      //   this.$message.warning('请上传更新包');
      //   return false;
      // }
      return true;
    },
    /**
     * 文件上传请求自定义
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileUploadRequest(fileInfo) {
      Log.info('uploadfileInfo', fileInfo);
      this.loading = true;
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          Log.info('upload', res);
          this.loading = false;
          this.form.downloadUrl = res[0].url;
          this.apkFileName = null;
        },
      });
    },
  }
}
</script>
